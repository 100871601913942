import * as R from 'ramda'
import * as reselect from 'reselect'
import assert from 'assert'
import { addDays } from 'date-fns'
import { bind } from 'redux-effects'
import { connect } from 'react-redux'
import { cookie } from 'redux-effects-universal-cookie'

import * as api from '@rushplay/api-client'

import { clientTypes } from '../client-type/types'
import { parseIfExisting } from '../util/parse-if-existing'

/**
 * App must be configurable with environment variables without rebuild. Yet all
 * `process.env` in client-side code are replaced with their values during
 * build.
 *
 * Due to that we are not using configuration environment variables in code
 * that is used both on the server and on the client. To propagate server’s
 * environment variables, we put them to `configuration` reducer’s initial state
 * which will be sent to client on every request.
 *
 * This means that all configuration environment variables values must be
 * retrieved from Redux state and not from `process.env` unless it is
 * server-oriented code.
 *
 * This does not apply to build-time variables like `NODE_ENV`.
 */
const INITIAL_STATE = {
  apiUrl: process.env.API_URL,
  brand: 'simplecasino',
  gameServerUrl: process.env.GAME_SERVER_URL,
  features: {
    lotto: parseIfExisting(process.env.LOTTO),
    progressiveWebApp: parseIfExisting(process.env.PROGRESSIVE_WEB_APP),
    rememberDepositAmount: parseIfExisting(process.env.REMEMBER_DEPOSIT_AMOUNT),
    sportsbook: parseIfExisting(process.env.SPORTSBOOK),
  },
  imgproxyUrl: process.env.IMGPROXY_URL,
  payerUrl: process.env.PAYER_URL,
  version: process.env.RAZZLE_APP_VERSION,
  fastTrackScript: process.env.FAST_TRACK_SCRIPT_URL,
}

export const INIT = 'simplecasino/config/INIT'
export const CLIENT_TYPE_UPDATED = 'simplecasino/config/CLIENT_TYPE_UPDATED'
export const METADATA_UPDATED = 'simplecasino/config/METADATA_UPDATED'

function createConnector(spec) {
  const selector = R.applySpec(spec)
  return connect(state => selector(state.configuration))
}

export function init(configuration) {
  return {
    type: INIT,
    error: configuration instanceof Error,
    payload: configuration,
  }
}

export function updateClientType(payload) {
  return bind(
    cookie('client_type', payload, {
      httpOnly: false,
      // TODO: Replace new Date with timestamp from redux-effects-timestamp
      expires: addDays(new Date(), 180),
      path: '/',
    }),
    () => ({
      type: CLIENT_TYPE_UPDATED,
      payload,
    })
  )
}

export function updateMetadata(payload) {
  return {
    type: METADATA_UPDATED,
    payload,
  }
}

export function fetchMetadata(url) {
  return api.fetchSeoDescription(
    { url },
    {
      success: res =>
        updateMetadata({
          title: R.path(['result', 'title'], res.value),
          description: R.path(['result', 'description'], res.value),
        }),
      version: 1,
    }
  )
}

export function fetch() {
  return api.fetchConfig({
    success: res =>
      init({
        brand: res.value.brand,
        cdnHost: res.value.cdnHost,
        cdnPrefix: res.value.cdnPrefix,
        defaultLanguage: res.value.defaultLanguage,
        environment: res.value.environment,
        fanLeague: res.value.fanLeague,
        footerSections: res.value.footerSections,
        gtmContainerId: res.value.gtmContainerId,
        languages: res.value.supportedLanguages,
        liveChatDepartments: res.value.liveChatDepartments,
        liveChatKey: res.value.liveChatKey,
        loggedOutPhoneVerification: res.value.loggedOutPhoneVerification,
        lottoland: res.value.lottoland,
        pusher: res.value.pusher,
        sbtech: res.value.sbtech,
        whatsAppNumber: res.value.whatsAppNumber,
      }),
    failure: () => init(new Error('errors.http.fetch-failure')),
    version: 1,
  })
}

export function reducer(state = INITIAL_STATE, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case INIT: {
      return R.merge(
        state,
        R.pick(
          [
            'apiUrl',
            'brand',
            'cdnHost',
            'cdnPrefix',
            'clientType',
            'defaultLanguage',
            'environment',
            'fanLeague',
            'features',
            'footerSections',
            'gameServerUrl',
            'gtmContainerId',
            'isIpInternal',
            'language',
            'languages',
            'liveChatKey',
            'liveChatDepartments',
            'loggedOutPhoneVerification',
            'lottoland',
            'pusher',
            'origin',
            'sbtech',
            'version',
            'whatsAppNumber',
          ],
          action.payload
        )
      )
    }

    case CLIENT_TYPE_UPDATED: {
      return R.merge(state, { clientType: action.payload })
    }

    case METADATA_UPDATED: {
      return R.assoc('metadata', action.payload, state)
    }

    default: {
      return state
    }
  }
}

export function getApiUrl(state) {
  // Allow overriding public API URL in NodeJS
  // We resolve it here and not in initial state to avoid leaking the URL to
  // client-side runtime, which will break the app if happen.
  if (process.env.INTERNAL_API_URL) {
    return process.env.INTERNAL_API_URL
  }

  return R.propOr('', 'apiUrl', state)
}

/**
 * Get basename based on language from URL
 * @param {Object} state
 * @returns {string} Basename if language available; `""` otherwise.
 */
export function getBasename(state) {
  const currentLanguage = getCurrentLanguage(state)
  const preferredLanguage = getPreferredLanguage(state)

  if (currentLanguage === preferredLanguage) {
    return `/${currentLanguage}`
  }

  return ''
}

export function getBrand(state) {
  return R.propOr('', 'brand', state)
}

export function getHasWebpSupport(state) {
  return R.pathOr(false, ['hasWebpSupport'], state)
}

export function getImgProxyUrl(state) {
  return R.path(['imgproxyUrl'], state)
}

/**
 * Get brand value compatible with backend API
 * @param {Object} state
 * @returns {string}
 */
export function getNormalizedBrand(state) {
  return getBrand(state)
}

export function getGameServerUrl(state) {
  // TODO: Must be empty string by default
  // Depends on: https://github.com/RushPlay/aws-infrastructure/pull/1
  return R.propOr('https://gamer.herogaming.com', 'gameServerUrl', state)
}

export function getGtmContainerId(state) {
  return R.pathOr('', ['gtmContainerId'], state)
}

export function getEnvironment(state) {
  return R.propOr('', 'environment', state)
}

export function getClientType(state) {
  return R.pathOr(clientTypes.UNKNOWN, ['clientType'], state)
}

export function getOrigin(state) {
  return R.pathOr('', ['origin'], state)
}

const LANGUAGES_FALLBACK = []
export function getLanguages(state) {
  const languages = R.propOr(LANGUAGES_FALLBACK, 'languages', state)

  if (getEnvironment(state) !== 'production') {
    return R.append('xx', languages)
  }

  return languages
}

export function getLiveChatKey(state) {
  return R.pathOr('', ['liveChatKey'], state)
}

const EMPTY_OBJECT = Object.freeze({})
export function getLiveChatDepartments(state) {
  return R.pathOr(EMPTY_OBJECT, ['liveChatDepartments'], state)
}

export function getMetadataTitle(state) {
  return R.pathOr('meta.title', ['metadata', 'title'], state)
}

export function getMetadataDescription(state) {
  return R.path(['metadata', 'description'], state)
}

export function getCdnHost(state) {
  return R.propOr('', 'cdnHost', state)
}

export function getCdnPrefix(state) {
  return R.propOr('', 'cdnPrefix', state)
}

export function getCdnUrl(state) {
  return `${getCdnHost(state)}/${getCdnPrefix(state)}`
}

export function getFanLeagueConfig(state) {
  return R.propOr({}, 'fanLeague', state)
}

export function getFeatures(state) {
  return R.propOr({}, 'features', state)
}

export function getFeature(state, props) {
  return R.propOr(false, props.feature, getFeatures(state))
}

export function getPusher(state) {
  return R.propOr({}, 'pusher', state)
}

export function isIpInternal(state) {
  return R.propOr(false, 'isIpInternal', state)
}

export function getCurrentLanguage(state) {
  return R.propOr('', 'language', state)
}

export function getDefaultLanguage(state) {
  return R.propOr('', 'defaultLanguage', state)
}

/**
 * Get preferred application language (taken from URL)
 * @param {Object} state
 * @returns {string}
 */
export function getPreferredLanguage(state) {
  const supportedLanguages = getLanguages(state)
  const language = getCurrentLanguage(state)

  if (R.includes(language, supportedLanguages)) {
    return language
  }
}

export function getVersion(state) {
  return R.propOr('', 'version', state)
}

export function getSbTechConfig(state) {
  return R.propOr('', 'sbtech', state)
}

export function getLottolandConfig(state) {
  return R.propOr('', 'lottoland', state)
}

export function getLottolandHost(state) {
  const lottolandConfig = getLottolandConfig(state)
  return R.path(['launchUrl'], lottolandConfig)
}

export function getLottolandOperatorId(state) {
  const lottolandConfig = getLottolandConfig(state)
  return R.path(['operatorId'], lottolandConfig)
}

export function getLiveCasinoChannel(state) {
  return R.path(['pusher', 'liveCasinoChannel'], state)
}

export const getConfig = reselect.createStructuredSelector({
  cdnHost: getCdnHost,
  cdnPrefix: getCdnPrefix,
  clientType: getClientType,
  environment: getEnvironment,
  languages: getLanguages,
  lottoland: getLottolandConfig,
  pusher: getPusher,
  sbtech: getSbTechConfig,
})

export function getWhatsAppNumber(state) {
  return R.path(['whatsAppNumber'], state)
}

export const getSocialMediaLinks = reselect.createSelector(
  [state => R.path(['footerSections', 'socialMedia'], state)],
  R.identity
)

/**
 * Higher-order component to inject `brand` into props
 *
 * @param {ReactComponent} component
 * @returns {ReactComponent}
 */
export const withBrand = createConnector({ brand: getBrand })

export function getPayerUrl(state) {
  const payerUrl = R.path(['payerUrl'], state)
  assert(payerUrl != null, 'Payer URL is unset; check configuration')
  return payerUrl
}

export function getLoggedOutPhoneVerification(state) {
  return R.pathOr(false, ['loggedOutPhoneVerification'], state)
}

export function getFastTrackScript(state) {
  return state.fastTrackScript || ''
}

export function getPaymentProviders(state) {
  return state.footerSections.paymentProviders
}
