import * as React from 'react'
import PropTypes from 'prop-types'

import css from '@styled-system/css'
import styled from '@emotion/styled'

const Wrapper = styled.label`
  ${props =>
    css({
      position: 'relative',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: props.disabled ? 'gray1' : 'white',
      fontSize: '14px',
      borderRadius: '4px',
      border: '1px solid',
      borderColor: 'gray3',
      overflow: 'hidden',
      height: '48px',
      boxShadow: '0 2px 8px 0px rgba(0, 0, 0, 0.1)',
    })}

  &:disabled {
    cursor: not-allowed;
  }
`

const InputField = styled.input`
  ${props =>
    css({
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
      color: props.disabled && 'gray8',
      fontFamily: 'head',
      fontWeight: 'bold',
      fontSize: 1,
      padding: 0,
      '&::placeholder': {
        color: 'gray6',
      },
    })}

  &:focus {
    outline: none;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const CurrencyWrapper = styled.div`
  ${css({
    fontFamily: 'head',
    fontSize: 0,
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'gray4',
    display: 'flex',
    justifyContent: 'center',
    pl: 1,
  })};
`

export function WalletInput(props) {
  const inputRef = React.useRef(null)
  const [focused, setFocused] = React.useState(props.autoFocus)

  const value =
    props.value === 0 || props.value === '0' ? '' : props.value.toString()

  React.useEffect(() => {
    // Handle focus state for wrapper styles
    if (focused && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focused, inputRef])

  function handleBlur() {
    setFocused(false)
    props.onBlur()
  }

  return (
    <Wrapper disabled={props.disabled} ref={inputRef}>
      {props.currency && <CurrencyWrapper>{props.currency}</CurrencyWrapper>}
      <InputField
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        id={props.id}
        inputMode="decimal"
        maxLength={props.maxLength}
        minLength={props.minLength}
        placeholder="0"
        type="text"
        value={value}
        onChange={props.onChange}
        onBlur={handleBlur}
        onFocus={() => setFocused(true)}
      />
    </Wrapper>
  )
}

WalletInput.propTypes = {
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  currency: PropTypes.oneOf(['EUR', 'CAD']),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}
