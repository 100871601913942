import * as React from 'react'
import { Helmet } from 'react-helmet-async'

export function TobiqueSeal() {
  //TODO: get the seal id from BE
  const sealId = 'a8db9f12-523a-45ef-bc24-7dfa6b0224f9'

  React.useEffect(() => {
    if (window.dlag_a8db9f12_523a_45ef_bc24_7dfa6b0224f9) {
      window?.dlag_a8db9f12_523a_45ef_bc24_7dfa6b0224f9.init()
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <script
          type="text/javascript"
          src={`https://${sealId}.seals.dlagglobal.com/dlag-seal.js`}
        />
      </Helmet>
      <div
        id={`dlag-${sealId}`}
        data-dlag-seal-id={sealId}
        data-dlag-image-size="40"
        data-dlag-image-type="basic-small"
      ></div>
    </React.Fragment>
  )
}
