import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import * as Theme from '@rushplay/theme'
import * as Common from '@rushplay/common'
import * as Icons from '@rushplay/icons'
import styled from '@emotion/styled'

import * as Analytics from '../analytics'
import { Fade } from '../components/common/fade'
import { HtmlContent } from '../components/common/html-content'
import { IconCircle } from '../components/common/icon-circle'
import { LanguageSwitch } from '../components/language-switch'
import { Logotype } from '../components/common/logotype'
import { Pwa } from '../scripts/pwa'
import { getTranslationKey } from '../utils/brand-translation-prefix'

import { Item } from './item'
import { MenuItems } from './menu-items'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(243, 245, 247, 0.6);
`

const Close = styled(IconCircle)`
  position: absolute;
  top: 15px;
  right: 20px;
  box-shadow: 0px 2px 4px rgba(97, 105, 107, 0.25);
`

const MoreMenu = styled.aside`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 130;
  height: 100vh;
  width: 100%;
  background-color: #f4f5f6;
  opacity: ${props => (props.active ? '100%' : '0%')};
  transform: translateX(${props => (props.active ? '0%' : '100%')});
  transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  font-size: ${Theme.fontSize(1)};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Theme.color('primary')};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${Theme.color('gray9')};
    border-radius: 10px;
  }
  ${Theme.mediaQuery.sm`
    width: 85vw;
    max-width: 320px;
  `};
  ${Theme.mediaQuery.md`
    width: 85vw;
    max-width: 350px;
  `};
`

const InfoLinkList = styled.ul`
  font-size: ${Theme.fontSize(0)};
  margin-top: ${Theme.spacing(1)};
  margin-bottom: ${Theme.spacing(2)};
`

const Version = styled.div`
  text-align: center;
  padding-top: ${Theme.spacing(0)};
  padding-bottom: ${Theme.spacing(2)};
  font-size: 10px;
`

/* eslint-disable react/prop-types */
function InfoLinkListItem(props) {
  return (
    <li>
      <Common.Space pb={1}>
        <Item
          as={Common.Link}
          to={props.to}
          target={props.target}
          title={props.title}
        />
      </Common.Space>
    </li>
  )
}

function ImageLinkItem(props) {
  return (
    <Common.Space pr={0} pb={1}>
      <Item
        as={Common.Link}
        to={props.to}
        target={props.target}
        title={props.alt}
      >
        <Common.Image
          size="fixed"
          height={34}
          src={props.src}
          alt={props.alt}
        />
      </Item>
    </Common.Space>
  )
}
/* eslint-enable react/prop-types */

export function Sidebar(props) {
  const history = ReactRouter.useHistory()
  const dispatch = ReactRedux.useDispatch()
  const location = ReactRouter.useLocation()
  const brand = ReactRedux.useSelector(state => state.configuration.brand)

  React.useEffect(
    () =>
      history.listen(() => {
        if (props.visible) {
          props.onClose()
        }
      }),
    [props.visible]
  )

  React.useEffect(() => {
    if (props.visible) {
      dispatch(
        Analytics.openSideMenu({
          path: `${location.pathname}${location.search}${location.hash}`,
          title: document.title,
        })
      )
    }
  }, [dispatch, props.visible, location])

  return (
    <React.Fragment>
      <Fade in={props.visible}>
        <Overlay onClick={props.onClose} />
      </Fade>
      <MoreMenu active={props.visible}>
        <Close onClick={props.onClose} hoverColor="primary">
          <Icons.IconWrapper>
            <Icons.IconClose />
          </Icons.IconWrapper>
        </Close>

        <Common.Box px={2} py={1} backgroundColor="white">
          <Logotype variant="dark" width="150" />
        </Common.Box>

        <Common.Box py={2} px={3}>
          <MenuItems
            items={items => [
              items.Casino,
              items.LiveCasino,
              items.Sports,
              items.ChatSupport,
              items.WhatsApp,
              items.Settings,
              items.SidebarButtons,
            ]}
          />
        </Common.Box>

        <Common.Box py={3} backgroundColor="darkblue">
          <footer>
            <Common.Space px={2}>
              <Common.Space pb={3}>
                <LanguageSwitch />
              </Common.Space>
              <InfoLinkList>
                <InfoLinkListItem
                  to="/terms-conditions-bonus"
                  title="side-bar.terms-conditions-bonus"
                />

                <InfoLinkListItem
                  to="/terms-conditions"
                  title="side-bar.terms-conditions"
                />
                {props.features.lotto && (
                  <InfoLinkListItem
                    to="/terms-conditions-lotto"
                    title="side-bar.terms-conditions-lotto"
                  />
                )}
                <InfoLinkListItem
                  to="/terms-conditions-sport"
                  title="side-bar.terms-conditions-sport"
                />
                <InfoLinkListItem to="/about-us" title="side-bar.about-us" />
                {props.licenseId !== 'curacao' && (
                  <InfoLinkListItem
                    to="http://www.heroaffiliates.com"
                    target="_blank"
                    title="side-bar.affiliates"
                  />
                )}
                {props.licenseId !== 'curacao' && (
                  <InfoLinkListItem
                    to="/cookie-policy"
                    title="side-bar.cookie-policy"
                  />
                )}
                <InfoLinkListItem to="/faq" title="side-bar.faq" />
                <InfoLinkListItem
                  to="/privacy-policy"
                  title="side-bar.privacy-policy"
                />
                <InfoLinkListItem
                  to="/responsible-gaming"
                  title="side-bar.responsible-gaming"
                />
                <InfoLinkListItem to="/security" title="side-bar.security" />
                {props.features.progressiveWebApp && <Pwa />}
              </InfoLinkList>
              <Common.Flex flexWrap="wrap">
                <React.Fragment>
                  <ImageLinkItem
                    src={props.translate(`${props.licenseId}-logo`)}
                    alt={props.licenseId}
                    target="_blank"
                    to="https://certificates.gamingcommission.ca/KGCCertificates.aspx?id=ff73e52e-35bb-4f2e-b7b5-55db26091db1"
                  />
                  <ImageLinkItem
                    src={props.translate('gamecare-logo')}
                    alt="Gambling Therapy"
                    target="_blank"
                    to="https://www.gamblingtherapy.org/en"
                  />
                </React.Fragment>
                <ImageLinkItem
                  src={props.translate('18-plus')}
                  alt="18+"
                  to="/responsible-gaming"
                />
              </Common.Flex>
              <Common.Space py={1}>
                <HtmlContent
                  html={{
                    __html: props.translate(
                      getTranslationKey(
                        brand,
                        `side-bar.${props.licenseId}-testimonial`
                      )
                    ),
                  }}
                />
              </Common.Space>
              <Version>{props.version}</Version>
            </Common.Space>
          </footer>
        </Common.Box>
      </MoreMenu>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  features: PropTypes.object.isRequired,
  licenseId: PropTypes.string.isRequired,
  manualSignup: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  version: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}
